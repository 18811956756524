.all-apps-container {
    width: 100%;
    color: #434444;
}


.app-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4em;
    width: 95%;
    margin: auto;
}

.app-container-text {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-top: 1em;
}

.app-container-text-title {
    font-size: 56px;
    font-weight:bold;
    font-family: 'Proxima-Nova', sans-serif;
    margin-bottom: 1em;
}

.app-container-text-subtitle {
    font-size: 25px;
    margin-bottom: 3em;
    font-family: 'Roboto', sans-serif;
    color: #434444;
}


.apps-get-started-button {
    background-color: #04A953;
    color: white;
    font-family: 'Roboto', sans-serif;
    padding: 1em 3em;
    border: none;
    border-radius: 4px;
    text-decoration: none;
}

.apps-get-started-button:hover {
    color: white;
    text-decoration: none;
}

.apps-get-started-button:active {
    background-color: #049349;
}


.app-container-text-reports-management-container {
    display: flex;
}

.app-container-text-reports-management-container-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 3em;
}

.app-report-img {
    width: 10em;
}

.app-container-img img{
    width: 700px;
}

.app-container-text-reports-management-container-text-title {
    font-size: 32px;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.app-container-text-reports-management-container-text-subtitle {
    margin-left: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
}

.app-container-help {
    margin: auto;
    width: 95%;
}

.help-card-alert {
    width: 500px;
}

.app-container-help-title {
    font-size: 56px;
    font-weight: bold;
    font-family: 'Proxima-Nova', sans-serif;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
}

.features-background {
    background-color: #EFF2F7;
    padding-bottom: 10em;
}

.app-cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10em;
}


@media only screen and (max-width: 780px) { 

    .app-report-img {
        max-width: 3em;
        margin-right: 2vw;
    }

    .apps-by-column {
        flex-direction: column-reverse;
    }

    .by-column {
        flex-direction: column;
    }
    
    .help-card-alert {
        width: 100%;
    }

    .app-container-img {
        width: 80vw;
    }

    .app-cards-container {
        flex-direction: column;
        justify-content: unset;
        width: 100%;
    }

    .app-container-text-title {
        font-size: 30px;
    }

    .app-container-text-subtitle {
        font-size: 20px;
    }

    .app-container-help-title {
        font-size: 30px;
    }

    .app-container-text-reports-management-container-text-title {
        font-size: 22px;
    }

    .features-background {
        padding-bottom: unset;
    }

    .app-container-text-reports-management-container-text-subtitle {
        font-size: 20px;
    }

    .app-container-text {
        width: 90%;
    }

    .apps-get-started-button {
        font-size: 15px;
        padding: 1em 2em;
    }
}