.about-services-marketing-container {
    margin: auto;
    width: 100%;
}

#services {
    width: 100%;
}

@media only screen and (max-width: 700px) { 
    .about-services-marketing-container {
        width: 100%;
    }
}