.navbar-other-buttons:hover {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
}

.navbar-other-buttons a {
    text-decoration: none;
}

.navbar-other-buttons select {
    text-decoration: none;
    color: black !important;
}


@media only screen and (max-width: 990px) {
    .navbar-other-buttons a {
        text-decoration: none;
        font-size: 18px;
    }
}