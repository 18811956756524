@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');
@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);

.about-container {
    width: 100%;
    color: #434444;
}

.title {
    font-size: 56px;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
}

.title-about {
    text-align: center;
    font-size: 4em;
    margin-top: 1em;
    margin-bottom: 2em;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
}

.text {
    font-size: 25px;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
}

.about-text-image-container img {
    border-radius: 5em;
}

.about-text-image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 95%;
    margin-bottom: 3em;
}

.about-text-container {
    display: flex;
    flex-direction: column;
    width: 55%;
}


.inactive-know-more-button {
    color: white;
    font-size: 20px;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    font-family: 'Roboto', sans-serif;
    border: none;
    border-radius: 0.3em;
    background-color: #04A953;
    margin-top: 2em;
    width: 40%;
    text-align: center;
    text-decoration: none;
}

.inactive-know-more-button:hover {
    text-decoration: none;
    color: white;
}

.margin-left-img-carbon {
    margin-left: 1em;
}


@media only screen and (max-width: 1100px) { 
    .inactive-know-more-button {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1500px) { 

    .title {
        font-size: 30px;
    }

    .text {
        font-size: 20px;
    }

    .about-text-image-container img {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) { 

    .text-image-column {
        flex-direction: column-reverse !important;
    }

    .margin-left-img-carbon {
        margin-left: unset;
    }

    .about-container {
        width: 100%;
    }

    .about-text-container {
        margin-top: 2em;
        width: 100%;
    }

    .about-text-image-container img {
        width: 100%;
        margin-top: 1em;
    }

    .title-about {
        margin-top: 3.5em;
    }

    .inactive-know-more-button {
        font-size: 14px;
        width: 100%;
    }

    a {
        font-size: 2vw;
        padding: unset;
    }

    .about-text-image-container {
        margin-bottom: unset;
    }

    .title {
        font-size: 1.5em;
    }

    .text {
        font-size: 1em;
    }

    .title-about {
        font-size: 1.5em;
    }

    .about-text-image-container {
        flex-direction: column;
        width: 100%;
    }
}