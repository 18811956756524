.apps-card-text {
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}


.apps-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0px 0px gray, 0 2px 10px rgba(150, 144, 144, 0.22);
    height: 100%;
}

.apps-card-title {
    font-size: 30px;
    font-weight: bold;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    margin-bottom: 1em;
    text-align: left;
    height: 100px;
}

.apps-card-subtitle {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
}

.apps-card-title-top {
    font-size: 25px;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

@media only screen and (max-width: 1000px) { 
    .apps-card-title {
        height: 100px;
    }
}


@media only screen and (max-width: 700px) { 
    .apps-card {
        height: 100%;
        width: 100%
    }

    .apps-card-title {
        font-size: 18px;
    }

    .apps-card-text {
        font-size: 12px;
    }

}