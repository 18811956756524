.client-area-container {
    width: 100%;
}


.client-area-container-cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}

.client-area-container-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0px 0px gray, 0 2px 10px rgba(150, 144, 144, 0.22);
    border-radius: 6px;
    width: 49%;
}

.client-area-container-title {
    font-family: 'Proxima-Nova', sans-serif;
    font-size: 56px;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.client-area-button {
    color: white;
    font-size: 20px;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    font-family: 'Roboto', sans-serif;
    border: none;
    border-radius: 0.3em;
    background-color: #04A953;
    margin-top: 1em;
    width: 40%;
    text-align: center;
    text-decoration: none;
    margin-bottom: 1em;
}

.client-area-button:hover {
    text-decoration: none;
    color: white;
}

.client-area-container-card-title {
    font-family: 'Proxima-Nova', sans-serif;
    font-size: 40px;
    color: #434444;
    font-weight: bold;
    margin-top: 1em;
}

@media only screen and (max-width: 700px) {

    .client-area-button {
        font-size: 3vw;
        width: 60%;
    }
    
    .client-area-container-card-title {
        font-size: 30px;
    }
}
