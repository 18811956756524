.contactform-title {
    font-size: 56px;
    margin-top: 1em;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 0.3em;
    text-align: center;
}

.contactform-subtitle {
    text-align: center;
    font-size: 32px;
    font-family: 'Roboto', sans-serif;
}

.contactform-card-container {
    margin: auto;
    width: 40%;
}


@media only screen and (max-width: 700px) {
    .contactform-card-container {
        width: 100%;
    }

    .contactform-title {
        font-size: 30px;
    }

    .contactform-subtitle {
        font-size: 20px;
    }

}