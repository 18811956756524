.marketing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 60%;
    color: #434444;
}

.marketing-container-title {
    text-align: center;
    font-size: 56px;
    margin-top: 1em;
    color: #434444;
    font-family: 'proxima-nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

.marketing-card-image {
    width: 100%;
    height: 22vw;
}

.marketing-container-subtitle {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 2em;
}

.marketing-buttons {
    margin-bottom: 4em;
}

.marketing-card-text-title {
    font-size: 20px;
}

.marketing-card-text-subtitle {
    font-size: 32px;
    font-weight: bold;
}

.marketing-cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.marketing-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 0px gray, 0 2px 10px rgba(150, 144, 144, 0.22);
    border-radius: 6px;
}

.marketing-card-text {
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
}

.marketing-slideshow-button {
    background-color: #EFEFEF;
    border: none;
    padding: 10px;
    margin: 4px 2px;
    border-radius: 50%;   
}


.marketing-slideshow-button:active, .marketing-slideshow-button:active, .marketing-slideshow-button:focus {
    background-color: #4CA65B;
}

@media only screen and (max-width: 700px) { 

    .marketing-cards-container {
        flex-direction: column;
    }

}