.video-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
}


@media only screen and (max-width: 700px) { 

}