@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

.pricing-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.pricing-title {
    text-align: center;
    font-size: 3vw;
    margin-top: 1em;
    color: #434444;
    font-family: 'proxima-nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

.pricing-subtitle {
    text-align: center;
    font-size: 1.5vw;
    margin-bottom: 2em;
}

.pricing-cards-container {
    display: flex;
    justify-content: center;
    margin: 4vw 0vw;
}

.pricing-card-title {
    font-size: 1.8em;
    color: #4CA65B;
    font-weight: bold;
}

.pricing-card {
    background-color: white;
    padding: 2vw 4vw;
    border-radius: 0.5em;
    width: 13em;
    margin: 0em 1vw;
}


.pricing-button {
    background-color: white;
    border: 2px solid #4CA65B;
    font-size: 0.9em;
    border-radius: 6px;
    margin: auto;
    padding: 1vw 2vw;
    font-family: 'proxima-nova', sans-serif;
}

.pricing-button:visited,.pricing-button:active, .pricing-button:focus {
    background-color: #4CA65B;
}

.pricing-button:hover {
    background-color: #4CA65B;
}

.pricing-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing-background {

    background-image: url('../img/uvas.jpg') ;
}