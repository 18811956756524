.services-container {
    width: 95%;
    margin: auto;
    margin-bottom: 5em;
}

.title-services {
    text-align: center;
    font-size: 56px;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

.services-card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.help-card {
    width: 28vw;
}


.subtitle-services {
    text-align: center;
    font-size: 1.5vw;
    margin-bottom: 2em;
}

@media only screen and (max-width: 700px) { 
    .help-card {
        width: 100%;
    }

    .services-card-container {
        flex-direction: column;
    }

}