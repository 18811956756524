.footer-container {
    color: white;
    padding: 2em 0em;
    background-color: #434444;
    margin-top: 3em;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}


.upper-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.adress-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.adress-container-left-side {
    display: flex;
    flex-direction: column;
}

.adress-container-left-side-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.adress-container-right-side {
    display: flex;
    flex-direction: column;
}

.hr-style {
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 80%;
}

.down-footer-container {
    display: flex;
    justify-content: space-around;
}

.projects-button {
    background-color: #434444;
    padding: 0.2em 1em;
    border: solid 2px white;
    color: white;
    border-radius: 4px;
}

@media only screen and (max-width: 700px) {
    .footer-container {
        font-size: 10px;
    }

    .adress-container-left-side-icons > img {
        margin-right: unset;
    }

}