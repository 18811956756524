.navbar-home {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.navbar-home-other-buttons {
    background-color: rgba(128, 128, 128, 0.3);
    margin-right: 6px;
}

.navbar-home-other-buttons a {
    text-decoration: none;
    margin: 1px;
}

.navbar-home-other-buttons a:hover {
    color: #04A953 !important;
}

.navbar-home-other-buttons:last-child {
    background-color: unset;
}

@media only screen and (max-width: 990px) {
    .navbar-nav{
        align-items: flex-end;
    }

    .navbar-home-other-buttons a {
        text-decoration: none;
        margin: 0px 3px;
    }

    .navbar-home-other-buttons {
        width: 15%;
    }
    .navbar-home-other-buttons:first-child {
        border-radius: 6px 6px 0px 0px;
    }
    .navbar-home-other-buttons:nth-child(6) {
        border-radius: 0px 0px 6px 6px;
    }
}

@media only screen and (min-width: 990px) {
    .navbar-home-other-buttons {
        border-radius: 6px;
    }
}