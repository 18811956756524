@import url(https://fonts.googleapis.com/css?family=Proxima+Nova);
@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Proxima+Nova);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-other-buttons:hover {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
}

.navbar-other-buttons a {
    text-decoration: none;
}

.navbar-other-buttons select {
    text-decoration: none;
    color: black !important;
}


@media only screen and (max-width: 990px) {
    .navbar-other-buttons a {
        text-decoration: none;
        font-size: 18px;
    }
}

.video-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
}


@media only screen and (max-width: 700px) { 

}
.navbar-home {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.navbar-home-other-buttons {
    background-color: rgba(128, 128, 128, 0.3);
    margin-right: 6px;
}

.navbar-home-other-buttons a {
    text-decoration: none;
    margin: 1px;
}

.navbar-home-other-buttons a:hover {
    color: #04A953 !important;
}

.navbar-home-other-buttons:last-child {
    background-color: unset;
}

@media only screen and (max-width: 990px) {
    .navbar-nav{
        align-items: flex-end;
    }

    .navbar-home-other-buttons a {
        text-decoration: none;
        margin: 0px 3px;
    }

    .navbar-home-other-buttons {
        width: 15%;
    }
    .navbar-home-other-buttons:first-child {
        border-radius: 6px 6px 0px 0px;
    }
    .navbar-home-other-buttons:nth-child(6) {
        border-radius: 0px 0px 6px 6px;
    }
}

@media only screen and (min-width: 990px) {
    .navbar-home-other-buttons {
        border-radius: 6px;
    }
}
.CountrysContainer {
    text-align: center;
}
.ClippingContainer {
    text-align: center;
}
.about-container {
    width: 100%;
    color: #434444;
}

.title {
    font-size: 56px;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
}

.title-about {
    text-align: center;
    font-size: 4em;
    margin-top: 1em;
    margin-bottom: 2em;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
}

.text {
    font-size: 25px;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
}

.about-text-image-container img {
    border-radius: 5em;
}

.about-text-image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 95%;
    margin-bottom: 3em;
}

.about-text-container {
    display: flex;
    flex-direction: column;
    width: 55%;
}


.inactive-know-more-button {
    color: white;
    font-size: 20px;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    font-family: 'Roboto', sans-serif;
    border: none;
    border-radius: 0.3em;
    background-color: #04A953;
    margin-top: 2em;
    width: 40%;
    text-align: center;
    text-decoration: none;
}

.inactive-know-more-button:hover {
    text-decoration: none;
    color: white;
}

.margin-left-img-carbon {
    margin-left: 1em;
}


@media only screen and (max-width: 1100px) { 
    .inactive-know-more-button {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1500px) { 

    .title {
        font-size: 30px;
    }

    .text {
        font-size: 20px;
    }

    .about-text-image-container img {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) { 

    .text-image-column {
        flex-direction: column-reverse !important;
    }

    .margin-left-img-carbon {
        margin-left: unset;
    }

    .about-container {
        width: 100%;
    }

    .about-text-container {
        margin-top: 2em;
        width: 100%;
    }

    .about-text-image-container img {
        width: 100%;
        margin-top: 1em;
    }

    .title-about {
        margin-top: 3.5em;
    }

    .inactive-know-more-button {
        font-size: 14px;
        width: 100%;
    }

    a {
        font-size: 2vw;
        padding: unset;
    }

    .about-text-image-container {
        margin-bottom: unset;
    }

    .title {
        font-size: 1.5em;
    }

    .text {
        font-size: 1em;
    }

    .title-about {
        font-size: 1.5em;
    }

    .about-text-image-container {
        flex-direction: column;
        width: 100%;
    }
}
.services-container {
    width: 95%;
    margin: auto;
    margin-bottom: 5em;
}

.title-services {
    text-align: center;
    font-size: 56px;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

.services-card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.help-card {
    width: 28vw;
}


.subtitle-services {
    text-align: center;
    font-size: 1.5vw;
    margin-bottom: 2em;
}

@media only screen and (max-width: 700px) { 
    .help-card {
        width: 100%;
    }

    .services-card-container {
        flex-direction: column;
    }

}
.services-card-text {
    padding: 3em;
    display: flex;
    color: #434444;
    flex-direction: column;
    align-items: center;
    text-align: start;
}


.services-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0px 0px gray, 0 2px 10px rgba(150, 144, 144, 0.22);
    height: 100%;
}

.services-card-title {
    font-size: 32px;
    font-weight: bold;
    font-family: 'Proxima-Nova', sans-serif;
    margin-bottom: 1em;
    text-align: center;
}

.services-card-subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
}

@media only screen and (max-width: 1400px) {
    .services-card-title {
        font-size: 18px;
    }

    .services-card-subtitle {
        font-size: 13px;
    }
}

@media only screen and (max-width: 700px) { 

    .services-card-title {
        font-size: 18px;
    }

    .services-card-subtitle {
        font-size: 12px;
    }

}
.pricing-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.pricing-title {
    text-align: center;
    font-size: 3vw;
    margin-top: 1em;
    color: #434444;
    font-family: 'proxima-nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

.pricing-subtitle {
    text-align: center;
    font-size: 1.5vw;
    margin-bottom: 2em;
}

.pricing-cards-container {
    display: flex;
    justify-content: center;
    margin: 4vw 0vw;
}

.pricing-card-title {
    font-size: 1.8em;
    color: #4CA65B;
    font-weight: bold;
}

.pricing-card {
    background-color: white;
    padding: 2vw 4vw;
    border-radius: 0.5em;
    width: 13em;
    margin: 0em 1vw;
}


.pricing-button {
    background-color: white;
    border: 2px solid #4CA65B;
    font-size: 0.9em;
    border-radius: 6px;
    margin: auto;
    padding: 1vw 2vw;
    font-family: 'proxima-nova', sans-serif;
}

.pricing-button:visited,.pricing-button:active, .pricing-button:focus {
    background-color: #4CA65B;
}

.pricing-button:hover {
    background-color: #4CA65B;
}

.pricing-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing-background {

    background-image: url(/static/media/uvas.79da786c.jpg);
}
.marketing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 60%;
    color: #434444;
}

.marketing-container-title {
    text-align: center;
    font-size: 56px;
    margin-top: 1em;
    color: #434444;
    font-family: 'proxima-nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

.marketing-card-image {
    width: 100%;
    height: 22vw;
}

.marketing-container-subtitle {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 2em;
}

.marketing-buttons {
    margin-bottom: 4em;
}

.marketing-card-text-title {
    font-size: 20px;
}

.marketing-card-text-subtitle {
    font-size: 32px;
    font-weight: bold;
}

.marketing-cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.marketing-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 0px gray, 0 2px 10px rgba(150, 144, 144, 0.22);
    border-radius: 6px;
}

.marketing-card-text {
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
}

.marketing-slideshow-button {
    background-color: #EFEFEF;
    border: none;
    padding: 10px;
    margin: 4px 2px;
    border-radius: 50%;   
}


.marketing-slideshow-button:active, .marketing-slideshow-button:active, .marketing-slideshow-button:focus {
    background-color: #4CA65B;
}

@media only screen and (max-width: 700px) { 

    .marketing-cards-container {
        flex-direction: column;
    }

}
.newsletter-container {
    background-color: #6290E3;
    width: 100%;
}

.newsletter-text-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5em;
}

.newsletter-title {
    font-size: 32px;
    font-family: 'Proxima-Nova', sans-serif;
    color: white;
}

.newsletter-form {
    display: flex;
}

.subscribe-button {
    border: none;
    background-color: #04A953;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: white;
    font-size: 1em;
    padding: 1vw 2vw;
}

.subscribe-input {
    padding: 2em;
    width: 40vw;
    border: none;
}

@media only screen and (max-width: 700px) {

    .subscribe-button {
        font-size: 10px;
    }
    .newsletter-title {
        font-size: 1em;
    }
    .subscribe-input {
        padding: unset;
    }

    .newsletter-text-container {
        flex-direction: column;
    }

    .newsletter-title {
        margin-bottom: 4em;
    }

    .subscribe-input {
        padding: 1em;
    }
}
.contactform-title {
    font-size: 56px;
    margin-top: 1em;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 0.3em;
    text-align: center;
}

.contactform-subtitle {
    text-align: center;
    font-size: 32px;
    font-family: 'Roboto', sans-serif;
}

.contactform-card-container {
    margin: auto;
    width: 40%;
}


@media only screen and (max-width: 700px) {
    .contactform-card-container {
        width: 100%;
    }

    .contactform-title {
        font-size: 30px;
    }

    .contactform-subtitle {
        font-size: 20px;
    }

}
.footer-container {
    color: white;
    padding: 2em 0em;
    background-color: #434444;
    margin-top: 3em;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}


.upper-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.adress-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.adress-container-left-side {
    display: flex;
    flex-direction: column;
}

.adress-container-left-side-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.adress-container-right-side {
    display: flex;
    flex-direction: column;
}

.hr-style {
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 80%;
}

.down-footer-container {
    display: flex;
    justify-content: space-around;
}

.projects-button {
    background-color: #434444;
    padding: 0.2em 1em;
    border: solid 2px white;
    color: white;
    border-radius: 4px;
}

@media only screen and (max-width: 700px) {
    .footer-container {
        font-size: 10px;
    }

    .adress-container-left-side-icons > img {
        margin-right: unset;
    }

}
.about2-container {
    margin: auto;
    width: 95%;
    color: #434444;
}

.about2-title {
    text-align: center;
    margin-bottom: 2em;
    font-size: 56px;
    font-weight: bold;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 1em;
}

.about2-subtitle {
    text-align: center;
    margin-bottom: 5em;
}

.about2-card-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10em;
    width: 100%;
}

.services-card-container {
    margin-bottom: 1em;
    width: 100%;
}

@media only screen and (max-width: 700px) { 
    .about2-container {
        width: 100%;
    }

    .about2-card-container {
        flex-direction: column;
    }

}
.all-apps-container {
    width: 100%;
    color: #434444;
}


.app-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4em;
    width: 95%;
    margin: auto;
}

.app-container-text {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-top: 1em;
}

.app-container-text-title {
    font-size: 56px;
    font-weight:bold;
    font-family: 'Proxima-Nova', sans-serif;
    margin-bottom: 1em;
}

.app-container-text-subtitle {
    font-size: 25px;
    margin-bottom: 3em;
    font-family: 'Roboto', sans-serif;
    color: #434444;
}


.apps-get-started-button {
    background-color: #04A953;
    color: white;
    font-family: 'Roboto', sans-serif;
    padding: 1em 3em;
    border: none;
    border-radius: 4px;
    text-decoration: none;
}

.apps-get-started-button:hover {
    color: white;
    text-decoration: none;
}

.apps-get-started-button:active {
    background-color: #049349;
}


.app-container-text-reports-management-container {
    display: flex;
}

.app-container-text-reports-management-container-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 3em;
}

.app-report-img {
    width: 10em;
}

.app-container-img img{
    width: 700px;
}

.app-container-text-reports-management-container-text-title {
    font-size: 32px;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.app-container-text-reports-management-container-text-subtitle {
    margin-left: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
}

.app-container-help {
    margin: auto;
    width: 95%;
}

.help-card-alert {
    width: 500px;
}

.app-container-help-title {
    font-size: 56px;
    font-weight: bold;
    font-family: 'Proxima-Nova', sans-serif;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
}

.features-background {
    background-color: #EFF2F7;
    padding-bottom: 10em;
}

.app-cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10em;
}


@media only screen and (max-width: 780px) { 

    .app-report-img {
        max-width: 3em;
        margin-right: 2vw;
    }

    .apps-by-column {
        flex-direction: column-reverse;
    }

    .by-column {
        flex-direction: column;
    }
    
    .help-card-alert {
        width: 100%;
    }

    .app-container-img {
        width: 80vw;
    }

    .app-cards-container {
        flex-direction: column;
        justify-content: unset;
        width: 100%;
    }

    .app-container-text-title {
        font-size: 30px;
    }

    .app-container-text-subtitle {
        font-size: 20px;
    }

    .app-container-help-title {
        font-size: 30px;
    }

    .app-container-text-reports-management-container-text-title {
        font-size: 22px;
    }

    .features-background {
        padding-bottom: unset;
    }

    .app-container-text-reports-management-container-text-subtitle {
        font-size: 20px;
    }

    .app-container-text {
        width: 90%;
    }

    .apps-get-started-button {
        font-size: 15px;
        padding: 1em 2em;
    }
}
.apps-card-text {
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}


.apps-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0px 0px gray, 0 2px 10px rgba(150, 144, 144, 0.22);
    height: 100%;
}

.apps-card-title {
    font-size: 30px;
    font-weight: bold;
    color: #434444;
    font-family: 'Proxima-Nova', sans-serif;
    margin-bottom: 1em;
    text-align: left;
    height: 100px;
}

.apps-card-subtitle {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
}

.apps-card-title-top {
    font-size: 25px;
    font-family: 'Proxima-Nova', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
}

@media only screen and (max-width: 1000px) { 
    .apps-card-title {
        height: 100px;
    }
}


@media only screen and (max-width: 700px) { 
    .apps-card {
        height: 100%;
        width: 100%
    }

    .apps-card-title {
        font-size: 18px;
    }

    .apps-card-text {
        font-size: 12px;
    }

}
.about-services-marketing-container {
    margin: auto;
    width: 100%;
}

#services {
    width: 100%;
}

@media only screen and (max-width: 700px) { 
    .about-services-marketing-container {
        width: 100%;
    }
}
.client-area-container {
    width: 100%;
}


.client-area-container-cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}

.client-area-container-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0px 0px gray, 0 2px 10px rgba(150, 144, 144, 0.22);
    border-radius: 6px;
    width: 49%;
}

.client-area-container-title {
    font-family: 'Proxima-Nova', sans-serif;
    font-size: 56px;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.client-area-button {
    color: white;
    font-size: 20px;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    font-family: 'Roboto', sans-serif;
    border: none;
    border-radius: 0.3em;
    background-color: #04A953;
    margin-top: 1em;
    width: 40%;
    text-align: center;
    text-decoration: none;
    margin-bottom: 1em;
}

.client-area-button:hover {
    text-decoration: none;
    color: white;
}

.client-area-container-card-title {
    font-family: 'Proxima-Nova', sans-serif;
    font-size: 40px;
    color: #434444;
    font-weight: bold;
    margin-top: 1em;
}

@media only screen and (max-width: 700px) {

    .client-area-button {
        font-size: 3vw;
        width: 60%;
    }
    
    .client-area-container-card-title {
        font-size: 30px;
    }
}

