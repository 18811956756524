.about2-container {
    margin: auto;
    width: 95%;
    color: #434444;
}

.about2-title {
    text-align: center;
    margin-bottom: 2em;
    font-size: 56px;
    font-weight: bold;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 1em;
}

.about2-subtitle {
    text-align: center;
    margin-bottom: 5em;
}

.about2-card-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10em;
    width: 100%;
}

.services-card-container {
    margin-bottom: 1em;
    width: 100%;
}

@media only screen and (max-width: 700px) { 
    .about2-container {
        width: 100%;
    }

    .about2-card-container {
        flex-direction: column;
    }

}