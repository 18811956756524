.newsletter-container {
    background-color: #6290E3;
    width: 100%;
}

.newsletter-text-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5em;
}

.newsletter-title {
    font-size: 32px;
    font-family: 'Proxima-Nova', sans-serif;
    color: white;
}

.newsletter-form {
    display: flex;
}

.subscribe-button {
    border: none;
    background-color: #04A953;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: white;
    font-size: 1em;
    padding: 1vw 2vw;
}

.subscribe-input {
    padding: 2em;
    width: 40vw;
    border: none;
}

@media only screen and (max-width: 700px) {

    .subscribe-button {
        font-size: 10px;
    }
    .newsletter-title {
        font-size: 1em;
    }
    .subscribe-input {
        padding: unset;
    }

    .newsletter-text-container {
        flex-direction: column;
    }

    .newsletter-title {
        margin-bottom: 4em;
    }

    .subscribe-input {
        padding: 1em;
    }
}